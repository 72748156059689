import React from 'react';

class Input extends React.Component {
  render() {
    let val = this.props.value;
    if (!val) val = '';
    return (
      <div className="form-group mb-5">
        <label className="block text-purple text-14 md:text-18">{this.props.label}</label>
        <input
          id={this.props.id}
          className={(this.props.bg || 'bg-gray-light md:bg-white') + ' w-full rounded-5 pl-15 py-5'}
          onChange={e => this.props.onChange(this.props.name, e.target.value)}
          type={this.props.type ? this.props.type : 'text'}
          name={this.props.name}
          required={this.props.required}
          placeholder={this.props.placeholder}
          autoComplete="off"
        />
      </div>
    );
  }
}
class Input1 extends React.Component {
  render() {
    let val = this.props.value;
    if (!val) val = '';
    return (
      <div className="form-group mb-5">
        <label className="block text-purple text-14 md:text-18">{this.props.label}</label>
        <input
          id={this.props.id}
          value={val ? val : ''}
          className={(this.props.bg || 'bg-gray-light md:bg-white') + ' w-full rounded-5 pl-15 py-5'}
          onChange={e => this.props.onChange(this.props.name, e.target.value)}
          type={this.props.type ? this.props.type : 'text'}
          name={this.props.name}
          required={this.props.required}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

class Textarea extends React.Component {
  render() {
    return (
      <div className="form-group mb-5">
        <label className="block text-purple text-14 md:text-18">{this.props.label}</label>

        <textarea
          id={this.props.id}
          className={
            (this.props.bg || 'bg-gray-light md:bg-white') +
            ' w-full rounded-5 px-15 py-5 text-14 md:text-16 placeholder-gray-placeholder ' +
            (this.props.resize || '') +
            (this.props.plColor ? 'placeholder-gray-placeholder' : '')
          }
          onChange={e => this.props.onChange(this.props.name, e.target.value)}
          rows={this.props.rows ? this.props.rows : 5}
          name={this.props.name}
          required={this.props.required}
          placeholder={this.props.placeholder}
          onFocus={e => (e.target.placeholder = '')}
          onBlur={e => (e.target.placeholder = this.props.placeholder)}
        />
      </div>
    );
  }
}

class Radio extends React.Component {
  render() {
    return (
      <label className={`relative pl-30 cursor-pointer text-16 md:text-18 flex items-center ${this.props.className}`}>
        <input
          className="absolute cursor-pointer opacity-0"
          onChange={() => this.props.onChange(this.props.name, this.props.value)}
          type="radio"
          name={this.props.name}
          value={this.props.value}
        />
        <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
        {this.props.children}
      </label>
    );
  }
}
class Checkbox extends React.Component {
  render() {
    return (
      <label className="relative pl-30 cursor-pointer text-16 flex items-center">
        <input
          className="absolute cursor-pointer opacity-0"
          onChange={() => this.props.onChange(this.props.name, this.props.value)}
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          checked={this.props.checked}
        />
        <span className={`checkmark absolute left-0 w-20 h-20 border-2 rounded-sm ${this.props.checked ? 'border-green' : 'border-black'}`} />
        {this.props.children}
      </label>
    );
  }
}
class CheckboxPurple extends React.Component {
  render() {
    return (
      <label className="relative pl-30 cursor-pointer text-16 md:text-18 flex items-center">
        <input
          className="absolute cursor-pointer opacity-0"
          onChange={() => this.props.onChange(this.props.name, this.props.value)}
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          checked={this.props.parentValue == this.props.value || this.props.checked ? true : false}
        />
        <span className="checkmark purple absolute left-0 w-20 h-20 border border-purple rounded-sm" />
        {this.props.children}
      </label>
    );
  }
}
class CheckboxGreen extends React.Component {
  render() {
    return (
      <label className="relative pl-30 cursor-pointer text-16 md:text-18 flex items-center">
        <input
          className="absolute cursor-pointer opacity-0"
          onChange={() => this.props.onChange(this.props.name, this.props.value)}
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          checked={this.props.parentValue == this.props.value || this.props.checked ? true : false}
        />
        <span className="checkmark green absolute left-0 w-20 h-20 border border-purple rounded-sm" />
        {this.props.children}
      </label>
    );
  }
}
class Heart extends React.Component {
  render() {
    return (
      <>
        <div>
          <input
            type="checkbox"
            className={'check-heart'}
            id={'id_' + this.props.from + '_' + this.props.id}
            onChange={() => this.props.onChange(this.props.name, this.props.value)}
            name={this.props.name}
            value={this.props.value}
            checked={this.props.checked}
          />
          <label className={'for-heart'} htmlFor={'id_' + this.props.from + '_' + this.props.id}>
            {this.props.from == 'list' ? (
              <div className={'flex items-center'}>
                <span className={'text-14 leading-20 text-gray-dark font-medium mr-2px  '}>add</span>
                <svg xmlns="http://www.w3.org/2000/svg" width={30} viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            ) : (
              <div className={'flex items-center'}>
                <span className={'text-14 leading-20 text-black font-medium mr-5'}>remove</span>
                <img src="https://admin.weprodigi.com/uploads/images/voiceover/cancel.svg" width={20} alt="cancel" />
              </div>
            )}
          </label>
        </div>
      </>
    );
  }
}
class CheckboxUnique extends React.Component {
  render() {
    return (
      <label className="relative pl-30 cursor-pointer text-16 md:text-18 flex items-center">
        <input
          className="absolute cursor-pointer opacity-0"
          onChange={() => this.props.onChange(this.props.name, this.props.value)}
          type="checkbox"
          name={this.props.name}
          value={this.props.value}
          checked={this.props.parentValue == this.props.value ? true : false}
        />
        <span className="checkmark absolute left-0 w-20 h-20 border border-purple rounded-sm" />
        {this.props.children}
      </label>
    );
  }
}
class Select extends React.Component {
  render() {
    return (
      <div className="form-group mb-5">
        <label className="block text-purple text-16 md:text-18">{this.props.label}</label>
        <select
          defaultValue=""
          className={(this.props.bg || 'bg-gray-light md:bg-white') + ' w-full rounded-5 pl-15 py-5'}
          name={this.props.name}
          required={this.props.required}
          onChange={e => this.props.onChange(this.props.name, e.target.value)}
        >
          <option value="" disabled>
            {this.props.selectText ? this.props.selectText : 'Please Select'}
          </option>

          {this.props.values.map(function (value, index) {
            return (
              <option key={index} value={value}>
                {value}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
class SelectWithNameValues extends React.Component {
  render() {
    return (
      <div className="form-group mb-5">
        <label className="block text-purple text-16 md:text-18">{this.props.label}</label>
        <select
          defaultValue=""
          className={(this.props.bg || 'bg-gray-light md:bg-white') + ' w-full rounded-5 pl-15 py-5'}
          name={this.props.name}
          required={this.props.required}
          onChange={e => this.props.onChange(this.props.name, e.target.value)}
        >
          <option value="" disabled>
            {this.props.selectText ? this.props.selectText : 'Please Select'}
          </option>
          {this.props.values.map(function (value, index) {
            return (
              <option key={index} value={value.val}>
                {value.text}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
export default Input;
export { Input, Textarea, Radio, Checkbox, CheckboxUnique, Select, SelectWithNameValues, CheckboxPurple, CheckboxGreen, Heart, Input1 };
